<template>
    <v-container fluid>
        <v-row align="start" justify="space-between">
            <v-col md="7" cols="12">
                <h2 class="text-h4 mb-8">{{ $t("user.buy.steps.payment-data.title") }}</h2>
                <v-alert v-if="showPeaWarning" border="left" outlined type="info">
                    <span style="color: black">
                        {{ $t("user.buy.steps.payment-data.warning-pea") }}
                    </span>
                </v-alert>
                <v-radio-group
                    v-model="selectPayment"
                    :rules="ruleSelectPayment"
                    @change="selectPaymentEvent"
                >
                    <v-radio v-for="n in paymentList" :key="n.value" :value="n.value">
                        <template #label>
                            {{ n.label }}
                            <v-tooltip v-if="n.tooltip" right>
                                <template #activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        class="close-icon ml-2"
                                        color="grey"
                                        v-on="on"
                                    >
                                        mdi-information
                                    </v-icon>
                                </template>
                                <span style="display: block; max-width: 250px">{{
                                    n.tooltip
                                }}</span>
                            </v-tooltip>
                        </template>
                    </v-radio>
                </v-radio-group>
                <v-input v-model="formData._local_valid" :rules="ruleLocalValid" type="hidden" />
            </v-col>
            <v-col md="5" cols="12" class="order-first order-md-last">
                <SubscriptionCard :order="order" />
            </v-col>
        </v-row>
        <v-row ref="refInfosPayment" align="center" justfiy="start">
            <v-col v-if="selectPayment === 'sepadata'">
                <h2 class="text-h5 mb-6">{{ $t("user.buy.steps.payment-data.infos-sepa") }}</h2>
                <p>
                    {{ $t("user.buy.steps.payment-data.text-sepa") }}
                </p>
                <div class="limit-form-width">
                    <v-text-field
                        v-model="sepadata.iban"
                        label="IBAN"
                        type="text"
                        :rules="rulesIban"
                    />
                    <v-text-field
                        v-model="sepadata.iban_foreign"
                        :label="$t('user.buy.steps.payment-data.foreign-iban-sepa')"
                        type="text"
                        :rules="rulesIbanForeign"
                    />
                    <v-text-field
                        v-model="sepadata.bic"
                        label="BIC"
                        type="text"
                        :rules="rulesBic"
                    />
                </div>
            </v-col>
            <v-col v-if="selectPayment === 'sepaautodata'">
                <h2 class="text-h5 mb-6">SEPA GoCardless</h2>
                <p>
                    Tunnel de paiement plus tard :
                    {{ capinc.capincpaymentoptions.payment_methods.sepaautopayment.creditor_id }}
                </p>
            </v-col>

            <v-col v-if="selectPayment === 'cryptodata'">
                <h2 class="text-h5 mb-6">{{ $t("user.buy.steps.payment-data.infos-crypto") }}</h2>
                <div class="limit-form-width">
                    <v-select
                        v-model="cryptodata.crypto_selected"
                        solo
                        rounded
                        :label="$t('user.buy.steps.payment-data.crypto.label-select')"
                        item-text="text"
                        item-value="value"
                        :rules="rulesCryptoSelected"
                        :items="cryptoList"
                    />
                    <v-text-field
                        v-model="cryptodata.address"
                        :label="$t('user.buy.steps.payment-data.crypto.label-address')"
                        type="text"
                        prefix="0x"
                        :rules="rulesCryptoAddress"
                    />
                    <v-alert border="left" type="warning">
                        <span style="color: black">
                            {{ $t("user.buy.steps.payment-data.crypto.warning-use-ledger") }}
                        </span>
                    </v-alert>
                </div>
                <v-alert
                    v-if="cryptoData"
                    border="left"
                    prominent
                    outlined
                    type="info"
                    class="mt-4"
                >
                    <div style="color: black">
                        <p class="text-h6">
                            {{ $t("user.buy.steps.payment-data.crypto.more-infos") }}
                        </p>
                        <p>
                            {{
                                $t("user.buy.steps.payment-data.crypto.transfert-on", {
                                    symbol: cryptoData.symbol
                                })
                            }}
                            <span class="font-weight-black">
                                {{ $t("user.buy.steps.payment-data.crypto.the-protocol") }}
                                {{ cryptoData.crypto.protocol }}
                            </span>
                            {{ $t("user.buy.steps.payment-data.crypto.the-protocol-end") }}
                        </p>
                        <p v-if="cryptoData.rate_eur">
                            {{
                                $t("user.buy.steps.payment-data.crypto.rate-parity-fix", {
                                    symbol: cryptoData.symbol
                                })
                            }}
                        </p>
                        <p v-else>
                            {{
                                $t("user.buy.steps.payment-data.crypto.rate-parity", {
                                    sourceApi: cryptoData.crypto.source_api
                                })
                            }}
                            <br />
                            {{ $t("user.buy.steps.payment-data.crypto.rate-daily") }}
                        </p>
                        <p class="font-weight-black text-center">
                            1.00 {{ cryptoData.symbol }} = {{ crypto_rate }}
                            {{ order.capinc.currency }}
                        </p>
                        <p class="text-center">ou</p>
                        <p class="font-weight-black text-center">
                            1.00 {{ order.capinc.currency }} = {{ cryptoAmountPerEuro }}
                            {{ cryptoData.symbol }}
                        </p>
                        <p>
                            {{ $t("user.buy.steps.payment-data.crypto.should-transfert") }}
                            <span class="font-weight-black">
                                {{ crypto_amount }} {{ cryptoData.symbol }} </span
                            >.
                        </p>
                    </div>
                </v-alert>
                <v-alert border="left" prominent outlined type="warning" class="mt-4">
                    <span style="color: black">
                        <p class="text-h6">
                            {{ $t("user.buy.steps.payment-data.crypto.sub-engagement") }}
                        </p>
                        <p>
                            {{ $t("user.buy.steps.payment-data.crypto.certified-owner-text") }}
                        </p>
                    </span>
                </v-alert>
            </v-col>
        </v-row>
        <v-row align="center" justfiy="center" class="px-6">
            <v-col>
                <EqsCheckbox
                    class="mt-0 font-weight-black"
                    :text="
                        $entity.represented().account_type == 'individual'
                            ? $t('user.payment-confirmation.accept-rgpd.individual')
                            : $t('user.payment-confirmation.accept-rgpd.company')
                    "
                    :value.sync="formData.accept_rgpd"
                    :is-required="true"
                    v-bind="{ ...schemaDouble }"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import SubscriptionCard from "../components/SubscriptionCard.vue";
import {
    getPaymentLabelFromId,
    getPaymentInfoFromId,
    getCryptoAmountStr,
    getEurToCryptoRate
} from "../util";
import { requiredR, bicR, ibanR } from "@equisafe-ui-vue/src/rules";

export default {
    name: "PaymentData",
    components: { SubscriptionCard },
    mixins: [formMixin],
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            currentPaymentData: null,
            selectPayment: null,
            sepaautodata: null,
            sepadata: {
                iban: null,
                iban_foreign: null,
                bic: null
            },
            cryptodata: {
                address: null,
                crypto_selected: null
            }
        };
    },
    computed: {
        showPeaWarning() {
            const envelopes = this.capinc?.capincpaymentoptions?.envelopes;
            if (!envelopes) {
                return false;
            }
            return ["pea", "pea-pme"].some(e => envelopes.includes(e));
        },
        ruleSelectPayment() {
            return [value => (!!value && value.length > 0) || this.$t("rules.choice-required")];
        },
        ruleLocalValid() {
            return [value => !!value && value === true];
        },
        rulesIban() {
            return [requiredR, ibanR];
        },
        rulesIbanForeign() {
            return [value => !value || value.length > 1 || this.$t("rules.invalid-format")];
        },
        rulesBic() {
            return [requiredR, bicR];
        },
        rulesCryptoSelected() {
            return [
                value => (value !== null && value.length > 0) || this.$t("rules.choice-required")
            ];
        },
        rulesCryptoAddress() {
            return [
                value =>
                    (value !== null &&
                        value.length > 0 &&
                        /^(?!(x|0x))[0-9a-zA-Z]{20,100}$/.test(value)) ||
                    this.$t("rules.crypto-wallet-address")
            ];
        },
        capinc() {
            return this.order.object || this.order.capinc;
        },
        paymentList() {
            let paymentList = [];
            const paymentMethods = this.capinc.capincpaymentoptions.payment_methods;
            if (paymentMethods.walletpayment) {
                paymentList.push({
                    value: "walletdata",
                    label: getPaymentLabelFromId(this, "walletdata"),
                    tooltip: getPaymentInfoFromId(this, "walletdata")
                });
            }
            if (paymentMethods.wiretransfertpayment) {
                paymentList.push({
                    value: "wiretransfertdata",
                    label: getPaymentLabelFromId(this, "wiretransfertdata")
                });
            }
            if (paymentMethods.sepapayment) {
                paymentList.push({
                    value: "sepadata",
                    label: getPaymentLabelFromId(this, "sepadata")
                });
            }
            if (paymentMethods.sepaautopayment) {
                paymentList.push({
                    value: "sepaautodata",
                    label: getPaymentLabelFromId(this, "sepaautodata")
                });
            }
            if (paymentMethods.cryptopayments && paymentMethods.cryptopayments.length > 0) {
                paymentList.push({
                    value: "cryptodata",
                    label: getPaymentLabelFromId(this, "cryptodata")
                });
            }
            return paymentList;
        },
        cryptoData() {
            if (this.cryptodata.crypto_selected) {
                let data = this.capinc.capincpaymentoptions.payment_methods.cryptopayments.find(
                    v => v.crypto.symbol === this.cryptodata.crypto_selected
                );
                data["symbol"] = data.crypto.symbol.split("-")[0];
                return data;
            }
            return null;
        },
        crypto_rate() {
            if (!this.cryptoData) {
                return null;
            }
            if (this.cryptoData.rate_eur) {
                return this.cryptoData.rate_eur;
            } else {
                return this.cryptoData.crypto.rate_eur;
            }
        },
        cryptoAmountPerEuro() {
            if (!this.cryptoData) {
                return null;
            }
            return getEurToCryptoRate(this.crypto_rate);
        },
        crypto_amount() {
            if (!this.cryptoData) {
                return null;
            }
            const cryptoAmount = getCryptoAmountStr(
                this.order.total_price,
                this.crypto_rate,
                this.cryptoData.crypto.decimals
            );
            return cryptoAmount;
        },
        cryptoList() {
            const cryptoList = this.capinc.capincpaymentoptions.payment_methods?.cryptopayments;
            if (!cryptoList) {
                return [];
            }
            return cryptoList.map(option => {
                return {
                    text: `${option.crypto.symbol.split("-")[0]} : ${option.crypto.protocol}`,
                    value: option.crypto.symbol
                };
            });
        }
    },
    watch: {
        selectPayment: {
            handler(val) {
                this.updateFormData();
            }
        },
        sepadata: {
            handler(val) {
                this.updateFormData();
            },
            deep: true
        },
        cryptodata: {
            handler(val) {
                this.updateFormData();
            },
            deep: true
        }
    },
    beforeMount() {
        let paymentSelected = null;
        if (this.order.payment_data) {
            paymentSelected = Object.entries(this.order.payment_data).find(e => {
                if (e[1] !== null) return true;
            });
        }
        if (paymentSelected !== null) {
            this.selectPayment = paymentSelected[0];
            if (this.selectPayment !== null && paymentSelected[1] !== null) {
                if (this.selectPayment.localeCompare("cryptodata") === 0) {
                    this.cryptodata = { ...paymentSelected[1] };
                } else if (this.selectPayment.localeCompare("sepadata") === 0) {
                    this.sepadata = { ...paymentSelected[1] };
                }
            }
        }
        this.$set(this.formData, "_local_valid", null);
    },
    methods: {
        validateSepaForm() {
            if (
                this.sepadata.iban &&
                this.sepadata.iban.length > 0 &&
                this.sepadata.bic &&
                this.sepadata.bic.length > 0
            ) {
                return true;
            }
            return false;
        },
        validateCryptoForm() {
            if (
                this.cryptodata.address &&
                this.cryptodata.address.length > 2 &&
                this.cryptodata.crypto_selected &&
                this.cryptodata.crypto_selected.length > 2
            ) {
                return true;
            }
            return false;
        },
        updateFormData() {
            let data = {};
            let tmpLocalValid = null;
            if (this.selectPayment === "walletdata") {
                tmpLocalValid = true;
                data.walletdata = { use_it: true };
            } else if (this.selectPayment === "wiretransfertdata") {
                tmpLocalValid = true;
                data.wiretransfertdata = { iban: "" };
            } else if (this.selectPayment === "sepadata") {
                if (this.validateSepaForm()) {
                    tmpLocalValid = true;
                    data.sepadata = { ...this.sepadata };
                }
            } else if (this.selectPayment === "sepaautodata") {
                tmpLocalValid = true;
                data.sepaautodata = { use_it: true };
            } else if (this.selectPayment === "cryptodata") {
                if (this.validateCryptoForm()) {
                    tmpLocalValid = true;
                    data.cryptodata = {
                        ...this.cryptodata
                    };
                }
            }
            this.$set(this.formData, "_local_valid", tmpLocalValid);

            if (Object.keys(data).length > 0) {
                this.$set(this.formData, "payment_data", { ...data });
            } else {
                this.$set(this.formData, "payment_data", null);
            }
        },
        selectPaymentEvent() {
            let refToScroll = null;
            switch (this.selectPayment) {
                case "sepadata":
                case "sepaautodata":
                case "cryptodata":
                    refToScroll = "refInfosPayment";
                    break;

                default:
                    refToScroll = null;
                    break;
            }
            if (refToScroll) {
                this.$nextTick(() =>
                    this.$refs[refToScroll].scrollIntoView({ behavior: "smooth" })
                );
            }

            this.updateFormData();
        }
    }
};
</script>

<style lang="scss" scoped>
.limit-form-width {
    width: 600px;
    margin: 0 auto;
    padding: 0 8px 0 4px;

    @media only screen and (max-width: 700px) {
        width: 100%;
    }
}
</style>
