import { i18n } from "@/services/plugins/i18n.js";

export const options = ctx => {
    return [
        {
            label: ctx.$t("user.payment.wallet"),
            value: "wallet",
            // disabled: !ctx.balance,
            imgs: [
                {
                    width: "60px",
                    height: "100%",
                    src: "/icons/coins.svg"
                }
            ],
            infos: [
                {
                    section: ctx.$t("user.wallet.wallet-balance").toUpperCase(),
                    value: ctx.$currency.display(ctx.balance)
                }
            ]
        }
    ];
};

export const fees = ctx => {
    return {};
    // const signatureFee = 1.5;
    // const blockchainFee = 0.5;
    // let res = {
    //   blockchain: {
    //     txt: ctx.$t("user.buy.blockchain-fee", {
    //       blockchainFee: ctx.$currency.display(blockchainFee),
    //     }),
    //     elClass: "blockchain-fee",
    //     value: blockchainFee,
    //   },
    // };
    // if (ctx.getDocsToSign)
    //   res["signature"] = {
    //     txt: ctx.$t("user.buy.signature-fee", {
    //       signatureFee: ctx.$currency.display(signatureFee),
    //     }),
    //     elClass: "signature-fee",
    //     value: signatureFee,
    //   };
    // return res;
};

export const payment_types = {
    subscribtion: {
        txt: i18n.t("payments.operation_type.subscribtion")
    },
    buyback: {
        txt: i18n.t("payments.operation_type.buyback")
    },
    partner_account: {
        txt: i18n.t("payments.operation_type.partner_account")
    },
    partner_account_deposit: {
        txt: i18n.t("payments.operation_type.partner_account_deposit")
    },
    partner_account_refund: {
        txt: i18n.t("payments.operation_type.partner_account_refund")
    },
    dividend: {
        txt: i18n.t("payments.operation_type.dividend")
    },
    coupon: {
        txt: i18n.t("payments.operation_type.coupon")
    },
    cession: {
        txt: i18n.t("payments.operation_type.cession")
    },
    cashflow: {
        txt: i18n.t("payments.operation_type.cashflow")
    },
    no_details_display: {
        txt: i18n.t("payments.operation_type.no_details_display")
    },
    capinc_order: {
        txt: i18n.t("payments.operation_type.capinc_order")
    }
};

export const types = Object.entries(payment_types).map(entry => {
    const [key, val] = entry;
    return {
        id: key,
        ...val
    };
});

export const statutes = {
    ask_pending: {
        // Unused
        txt: i18n.t("user.buy.ask_pending"),
        display: true,
        color: "warning",
        eqsType: "warning",
        id: "pending"
    },
    pending: {
        logo: "/images/equisafe-logo-orange.svg",
        title: i18n.t("user.buy.pending-title"),
        txt: i18n.t("user.buy.pending-title"),
        display: true,
        color: "warning",
        eqsType: "warning",
        id: "pending",
        doneMessage: i18n.t("user.buy.pending-done-msg"),
        message: i18n.t("user.buy.pending-msg"),
        chip: i18n.t("user.buy.signed-idle-msg")
    },
    pending_payment: {
        logo: "/images/equisafe-logo-orange.svg",
        title: i18n.t("user.buy.signed.title"),
        txt: i18n.t("user.buy.signed.txt"),
        display: true,
        color: "warning",
        eqsType: "warning",
        id: "pending_payment",
        message: i18n.t("user.buy.signed-msg"),
        chip: i18n.t("user.buy.sequestered.title")
    },
    reserved: {
        // Unused
        logo: "/images/equisafe-logo-orange.svg",
        title: i18n.t("user.buy.reserved"),
        txt: i18n.t("user.buy.reserved"),
        display: true,
        color: "info",
        eqsType: "info",
        id: "reserved",
        message: i18n.t("user.buy.reserved-msg")
    },
    sequestered: {
        logo: "/images/equisafe-logo-orange.svg",
        title: i18n.t("user.buy.sequestered.title"),
        txt: i18n.t("user.buy.sequestered.txt"),
        display: true,
        color: "info",
        eqsType: "info",
        id: "sequestered",
        idleMessage: i18n.t("user.buy.sequestred-idle-msg"),
        message: i18n.t("user.buy.sequestered-msg"),
        doneMessage: i18n.t("user.buy.sequestred-done-msg"),
        chip: i18n.t("user.buy.validated-short-msg")
    },
    signed: {
        logo: "/images/equisafe-logo-orange.svg",
        title: i18n.t("user.buy.signed.title"),
        txt: i18n.t("user.buy.signed.txt"),
        display: true,
        color: "warning",
        eqsType: "warning",
        id: "signed",
        idleMessage: i18n.t("user.buy.signed-idle-msg"),
        message: i18n.t("user.buy.signed-msg"),
        doneMessage: i18n.t("user.buy.signed-done-msg"),
        chip: i18n.t("user.buy.sequestred-wait-short-msg")
    },
    error: {
        // unused
        logo: "/images/equisafe-logo-red.svg",
        title: i18n.t("error.error"),
        txt: i18n.t("error.error"),
        error: true,
        color: "error",
        eqsType: "error",
        id: "error",
        message: i18n.t("user.buy.error-msg"),
        chip: i18n.t("error.error")
    },
    cancel: {
        logo: "/images/equisafe-logo-blue.svg",
        title: i18n.t("label.cancelation"),
        txt: i18n.t("label.canceled"),
        error: true,
        color: "error",
        eqsType: "error",
        id: "cancel",
        message: i18n.t("user.buy.cancelation-msg"),
        chip: i18n.t("label.canceled")
    },
    success: {
        logo: "/images/equisafe-logo-green.svg",
        title: i18n.t("user.buy.success-txt"),
        txt: i18n.t("user.buy.success-txt"),
        display: true,
        color: "success",
        eqsType: "success",
        id: "success",
        idleMessage: i18n.t("user.buy.success-idle-msg"),
        message: i18n.t("user.buy.success-msg"),
        doneMessage: i18n.t("user.buy.success-done-msg"),
        chip: i18n.t("user.buy.success-chip-txt")
    },
    validated: {
        logo: "/images/equisafe-logo-green.svg",
        title: i18n.t("user.buy.validated-txt"),
        txt: i18n.t("user.buy.validated-txt"),
        display: true,
        color: "success",
        eqsType: "success",
        id: "validated",
        idleMessage: i18n.t("user.buy.validated-idle-msg"),
        message: i18n.t("user.buy.validated-msg"),
        doneMessage: i18n.t("user.buy.validated-done-msg"),
        chip: i18n.t("user.buy.validated-chip-txt")
    },
    refused: {
        logo: "/images/equisafe-logo-red.svg",
        title: i18n.t("label.refused"),
        txt: i18n.t("label.refused"),
        error: true,
        color: "error",
        eqsType: "error",
        id: "refused",
        message: i18n.t("user.buy.refused-msg"),
        chip: i18n.t("label.refused")
    }
};

export const payment_modes = {
    card: {
        text: i18n.t("user.payment.credit-card")
    },
    transfer: {
        text: i18n.t("user.payment.wire-transfer")
    },
    wallet: {
        text: i18n.t("user.payment.wallet-transfer")
    }
};

export const asset_op_types = {
    Souscription: {
        text: i18n.t("movements.asset-op-type.souscription"),
        price: i18n.t("movements.label.price.souscription-txt"),
        receiver: i18n.t("movements.label.receiver.souscription-txt"),
        receiverEmail: i18n.t("movements.label.receiver.souscription-email"),
        receiverFirstName: i18n.t("movements.label.receiver.souscription-first-name"),
        receiverLastName: i18n.t("movements.label.receiver.souscription-last-name")
    },
    Cession: {
        text: i18n.t("movements.asset-op-type.cession"),
        price: i18n.t("movements.label.price.cession-txt"),
        receiver: i18n.t("movements.label.receiver.cession-txt"),
        receiverEmail: i18n.t("movements.label.receiver.cession-email"),
        receiverFirstName: i18n.t("movements.label.receiver.cession-first-name"),
        receiverLastName: i18n.t("movements.label.receiver.cession-last-name"),
        emitter: i18n.t("movements.label.emitter.cession-txt"),
        emitterEmail: i18n.t("movements.label.emitter.cession-email"),
        emitterFirstName: i18n.t("movements.label.emitter.cession-first-name"),
        emitterLastName: i18n.t("movements.label.emitter.cession-last-name")
    },
    Division: {
        text: i18n.t("movements.asset-op-type.division")
    },
    Multiplication: {
        text: i18n.t("movements.asset-op-type.multiplication")
    },
    Annulation: {
        text: i18n.t("movements.asset-op-type.annulation"),
        price: i18n.t("movements.label.price.annulation-txt"),
        emitter: i18n.t("movements.label.emitter.cession-txt"),
        emitterEmail: i18n.t("movements.label.emitter.cession-email"),
        emitterFirstName: i18n.t("movements.label.emitter.cession-first-name"),
        emitterLastName: i18n.t("movements.label.emitter.cession-last-name")
    }
};

export const op_types = Object.entries(asset_op_types).map(entry => {
    const [key, val] = entry;
    return {
        id: key,
        ...val
    };
});

export const payment_actions = {
    ask: {
        txt: i18n.t("payments.ask.txt")
    },
    to_send: {
        txt: i18n.t("payments.to_send.txt")
    },
    download: {
        txt: i18n.t("payments.download.txt")
    }
};
