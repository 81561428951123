import store from "@/store/store";

import { requiredR, max100R, protectedCharR, min8R, validUrlR } from "@equisafe-ui-vue/src/rules";

export const postTitleR = [requiredR, min8R, max100R, protectedCharR];
export const postTypeR = [requiredR];
export const fieldYoutubeLinkR = [requiredR, min8R];
export const fieldTextR = [requiredR];
export const fieldCtaNameR = [requiredR];
export const fieldCtaLinkR = [requiredR, validUrlR];

export function isEntityCapincAdmin(entity, capincId) {
    try {
        const project = store.getters.projects.find(e => e.id === capincId);
        return project && project.shareledger.company.id === entity.id;
    } catch (e) {
        console.error("Cant check if entity is capinc admin : ", e);
    }
    return false;
}

export function getPostLabelColor(label) {
    switch (label) {
        case "video":
            return "#EA7B6C";
        case "report":
            return "#9370CD";
        case "newsletter":
            return "#6299B8";
        case "other":
        default:
            return "#9370CD";
    }
}

export function getPostLabelText(ctx, label) {
    switch (label) {
        case "video":
            return ctx.$t("projects.reporting.posts-label.video");
        case "report":
            return ctx.$t("projects.reporting.posts-label.report");
        case "newsletter":
            return ctx.$t("projects.reporting.posts-label.newsletter");
        case "other":
        default:
            return ctx.$t("projects.reporting.posts-label.other");
    }
}

export function getButtonText(ctx, type) {
    switch (type) {
        case "text":
            return ctx.$t("projects.reporting.btn-label.text");
        case "document":
            return ctx.$t("projects.reporting.btn-label.document");
        case "cta":
            return ctx.$t("projects.reporting.btn-label.cta");
        case "youtube_link":
        default:
            return ctx.$t("projects.reporting.btn-label.youtube-link");
    }
}
