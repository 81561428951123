<template>
    <v-chip v-if="kycStatus" outlined>
        <v-icon class="mr-2" :color="kycStatus.color">
            {{ kycStatus.icon }}
        </v-icon>
        <span>{{ kycStatus.text }}</span>
    </v-chip>
</template>

<script>
import { validationStatusKyc } from "@/components/views/user/clients/tools.js";

export default {
    name: "KycStatus",
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        entity: {
            type: Object,
            required: true
        }
    },

    computed: {
        kycStatus() {
            return validationStatusKyc(
                this.entity.lemonway_state ? this.entity.lemonway_state : "waiting"
            );
        }
    }
};
</script>

<style lang="scss" scoped></style>
