import en from "./languages/en.json";
import fr from "./languages/fr.json";
import autoBind from "auto-bind";
import { getCookie, setCookie } from "../../util";

export const languages = {
    en,
    fr
};

export const languagesIcons = {
    en: "gb",
    fr: "fr"
};

export const languagesArr = Object.keys(languages);

export function navigatorLocale() {
    const navigatorLocale = window.navigator.language.slice(0, 2);
    if (navigatorLocale === "en") return "en";
    else return "fr";
}

export function cookieLocale() {
    const cookieLang = getCookie("eqs_language", false);
    if (languagesArr.includes(cookieLang)) return cookieLang;
    return null;
}

// Returns either 'en' or 'fr'
export function currentLanguage() {
    const locale = cookieLocale() || navigatorLocale();
    return locale;
}

export class LanguageService {
    constructor(api, entityService, i18n) {
        if (!api) {
            throw new Error("Missing `api` option");
        }
        if (!entityService) {
            throw new Error("Missing `entityService` option");
        }
        if (!i18n) {
            throw new Error("Missing `i18n` option");
        }
        this.api = api;
        this.entity = entityService;
        this.i18n = i18n;
        this.languages = languagesArr;
        this.languagesText = {
            en: i18n.t("languages.en"),
            fr: i18n.t("languages.fr")
        };
        autoBind(this);
    }

    setUniverseLocaleMessages(universeLanguages) {
        if (universeLanguages)
            for (let [key, value] of Object.entries(universeLanguages)) {
                this.i18n.mergeLocaleMessage(key, value);
            }
    }

    current() {
        return this.i18n.locale; // 'en', 'fr'
    }

    currentLanguage() {
        return this.allLanguages.find(lang => lang.value === this.i18n.locale);
    }

    allLanguages() {
        return languagesArr.map(lang => {
            return {
                value: lang,
                text: this.languagesText[lang],
                src: this.countryFlag({ alphacode: lang })
            };
        });
    }

    others() {
        const currentLanguage = this.current();
        return this.languages.filter(lang => lang !== currentLanguage);
    }

    countryFlag(country) {
        if (country && country.alphacode) {
            const flag =
                languagesIcons[country.alphacode.toLowerCase()] || country.alphacode.toLowerCase();
            return `https://public-documents-eqs.s3.eu-west-3.amazonaws.com/countries/${flag}.svg`;
        }
        return "";
    }

    async changeTo(lang, setDjangoLanguage, redirectTo = "") {
        if (!this.languages.includes(lang)) {
            console.error(`cannot change language to ${lang}`);
            return;
        }
        if (setDjangoLanguage) {
            await this.setDjangoLanguage(lang);
        }
        setCookie("eqs_language", lang);
        setCookie("django_language", lang);
        if (redirectTo) window.location = redirectTo;
        else window.location.reload();
    }

    // called in Login.vue (if user set a language before registering)
    // called in LanguagePreference.vue (if user changes language)
    async setDjangoLanguage(lang) {
        console.log(`setting django language to ${lang}`);
        return this.api.setLanguage(lang);
    }
}
