<template>
    <v-row>
        <v-col v-for="(item, index) in summaryInfos" :key="index" sm="6" cols="12" class="pt-0">
            <EqsCard
                class="summary-card"
                v-bind="{
                    title: item.title,
                    loading: loading || localLoading,
                    height: '100%'
                }"
            >
                <template v-slot:title>
                    <EqsTextDisplay eqs-type="subtitle" :value="item.title" :loading="loading" />
                </template>
                <v-flex v-if="item.items" layout column align-center justify-space-around>
                    <div v-for="(info, index2) in item.items" :key="index2">
                        <EqsTextDisplay
                            v-if="info.section"
                            pt-2
                            class="section"
                            eqs-type="body"
                            v-bind="{ value: info.section }"
                        />
                        <v-flex
                            v-if="!!info.total && info.total.length > 0"
                            layout
                            class="column align-center"
                        >
                            <!-- <EqsTextDisplay
                v-if="!displayInfo(info, 'EUR') && !displayInfo(info, 'USD')"
                class="money text-xs-center mb-2"
                v-bind="{ value: 'Total: ' + info.total }"
                eqs-type="subtitle"
              /> -->
                            <EqsTextDisplay
                                v-if="displayInfo(info, 'EUR')"
                                class="subsection text-xs-center my-3"
                                v-bind="{
                                    value:
                                        'EUR: ' +
                                        $currency.displayWithCurrency(info.money_eur, 'EUR')
                                }"
                                eqs-type="subtitle"
                            />
                            <EqsTextDisplay
                                v-if="displayInfo(info, 'USD')"
                                class="subsection text-xs-center my-3"
                                v-bind="{
                                    value:
                                        'USD: ' +
                                        $currency.displayWithCurrency(info.money_usd, 'USD')
                                }"
                                eqs-type="subtitle"
                            />
                        </v-flex>
                        <EqsTextDisplay
                            v-else-if="!!info.total && !info.total.length"
                            v-bind="{ value: $t('portfolio.summary.no-data-to-display') }"
                            class="subsection text-xs-center my-3"
                        />
                        <EqsTextDisplay
                            v-else-if="info.money == 0"
                            pt-2
                            pb-3
                            class="money text-xs-center mb-2"
                            v-bind="{ value: '- €' }"
                            eqs-type="title"
                        />
                        <EqsTextDisplay
                            v-else-if="info.percentage"
                            class="money text-xs-center mb-2"
                            v-bind="{
                                value: 'Total: ' + info.percentage.toFixed(2) + ' %'
                            }"
                            eqs-type="subtitle"
                        />
                        <EqsTextDisplay
                            v-else-if="info.percentage == 0"
                            pt-2
                            pb-3
                            class="money text-xs-center mb-2"
                            v-bind="{ value: '- %' }"
                            eqs-type="title"
                        />
                        <EqsTextDisplay
                            v-if="info.txt"
                            class="text-xs-center mb-5"
                            v-bind="{ value: info.txt }"
                        />
                        <v-flex
                            v-if="info.items"
                            layout
                            :class="[
                                info.row
                                    ? 'layout row align-center justify-start'
                                    : 'layout column align-center'
                            ]"
                        >
                            <v-flex
                                v-for="(item2, index3) in info.items"
                                :key="index3"
                                :class="
                                    item2.row
                                        ? 'layout row align-center justify-space-betwen'
                                        : 'layout column align-start'
                                "
                            >
                                <EqsTextDisplay
                                    v-if="item2.subsection"
                                    layout
                                    no-wrap
                                    class="subsection mx-2 mb-2"
                                    v-bind="{ value: item2.subsection }"
                                    eqs-type="subtitle"
                                />
                                <EqsTextDisplay
                                    v-if="item2.percent"
                                    class="percent mx-3"
                                    v-bind="{ value: item2.percent }"
                                    eqs-type="subtitle"
                                />
                            </v-flex>
                        </v-flex>
                    </div>
                </v-flex>
                <v-flex v-if="item.chart">
                    <EqsPie
                        v-if="item.chart.config"
                        style="height: inherit; width: 100%"
                        :config="item.chart.config"
                    />
                </v-flex>
            </EqsCard>
        </v-col>
    </v-row>
</template>

<script>
import { percentage } from "@equisafe-ui-shared/util";
import { types, realEstate } from "@/util/company.js";
export default {
    name: "Summary",
    props: {
        companies: {
            type: Array,
            default: () => []
        },
        valuation: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            summaryInfos: null,
            rate: null,
            rateObj: null,
            localLoading: false
        };
    },
    watch: {
        companies(val) {
            if (val && val.length) {
                this.getSummaryInfos();
            }
        },
        valuation(val) {
            if (val) {
                this.getSummaryInfos();
            }
        }
    },
    async mounted() {
        this.localLoading = true;
        const res = await this.$api.getExchangeRate();
        this.rateObj = res.data;
        this.getSummaryInfos();
        this.localLoading = false;
    },
    methods: {
        displayInfo(info, type) {
            if (!info) return false;
            else if (info.money_eur > 0 && type == "EUR") return true;
            else if (info.money_usd > 0 && type == "USD") return true;
            return false;
        },
        portfolioTotalValue() {
            return this.companies.reduce((acc, cur) => acc + cur.value, 0);
        },
        formatIndustryInfos() {
            let labels = [];
            let data = [];
            let infos = [];
            let industries = {};

            this.companies.forEach(company => {
                //Get translation for industry type
                let label = "";
                if (company.industry_type === "real_estate")
                    label = `${types[company.industry_type].txt}${
                        company.real_estate_type
                            ? "- " + realEstate[company.real_estate_type].txt
                            : ""
                    }`;
                else label = types[company.industry_type].txt;
                if (!labels.includes(label)) {
                    labels.push(label);
                    industries[label] = [company];
                } else {
                    industries[label].push(company);
                }
            });
            for (let companies of Object.values(industries)) {
                let totalValue = 0;
                companies.forEach(company => {
                    if (company.country.alphacode == "US") {
                        totalValue += company.value * this.rateObj.EUR;
                    } else {
                        totalValue += company.value;
                    }
                });

                const info = {
                    value: totalValue,
                    percentage: percentage(
                        totalValue,
                        this.getRawTotal(this.valuation, "net_asset_value")
                    ),
                    value: this.$currency.abreviate(totalValue, true, "0.00")
                };
                infos.push(info);
                data.push(info.percentage);
            }
            return {
                labels,
                data,
                infos
            };
        },
        getIndustryChartConfig() {
            if (!this.companies || !this.companies.length) return null;
            return {
                ...this.formatIndustryInfos(),
                name: {
                    formatter: function (value, ctx) {
                        return ctx.chart.data.labels[ctx.dataIndex];
                    }
                },
                value: {
                    textAlign: "center",
                    formatter: function (value, ctx) {
                        return ctx.active
                            ? ctx.dataset.datalabels.infos[ctx.dataIndex].value
                            : ctx.dataset.datalabels.infos[ctx.dataIndex].percentage + " %";
                    }
                },
                datalabels: {
                    display: function (ctx) {
                        return ctx.dataset.data[ctx.dataIndex] >= 5.0;
                    }
                },
                colorIndex: 6
            };
        },
        formatCountriesInfos() {
            let labels = [];
            let data = [];
            let infos = [];
            let countries = {};
            this.companies.forEach(company => {
                const label = `${company.nationality.name} (${company.nationality.alphacode})`;
                if (!labels.includes(label)) {
                    labels.push(label);
                    countries[label] = [company];
                } else {
                    countries[label].push(company);
                }
            });
            for (let companies of Object.values(countries)) {
                let totalValue = 0;
                companies.forEach(company => {
                    if (company.country.alphacode == "US") {
                        totalValue += company.value * this.rateObj.EUR;
                    } else {
                        totalValue += company.value;
                    }
                });

                const country = {
                    value: totalValue,
                    alphacode: companies[0].nationality.alphacode,
                    name: companies[0].nationality.name
                };
                const info = {
                    ...country,
                    percentage: percentage(
                        country.value,
                        this.getRawTotal(this.valuation, "net_asset_value")
                    ),
                    value: this.$currency.abreviate(country.value, true, "0.00")
                };
                infos.push(info);
                data.push(info.percentage);
            }
            return {
                labels,
                data,
                infos
            };
        },
        getCountryChartConfig() {
            if (!this.companies || !this.companies.length) return null;
            return {
                ...this.formatCountriesInfos(),
                name: {
                    formatter: function (value, ctx) {
                        return ctx.active
                            ? ctx.dataset.datalabels.infos[ctx.dataIndex].name
                            : ctx.dataset.datalabels.infos[ctx.dataIndex].alphacode;
                    }
                },
                value: {
                    formatter: function (value, ctx) {
                        return ctx.active
                            ? ctx.dataset.datalabels.infos[ctx.dataIndex].value
                            : ctx.dataset.datalabels.infos[ctx.dataIndex].percentage + " %";
                    }
                },
                datalabels: {
                    display: function (ctx) {
                        return ctx.dataset.data[ctx.dataIndex] >= 5.0;
                    }
                },
                colorIndex: 3
            };
        },
        async getSummaryInfos() {
            this.summaryInfos = [
                {
                    title: this.$t("portfolio.summary.cumulated-investment"),
                    items: [
                        {
                            section: this.$t("portfolio.summary.cumulated-investment-section"),
                            type: "Total",
                            money_eur: this.getTotalAllInvestment("EUR"),
                            money_usd: this.getTotalAllInvestment("USD"),
                            total: this.$currency.displayWithCurrency(
                                this.companies.reduce(
                                    (acc, cur) => acc + Math.abs(cur.investment_sum_eur),
                                    0
                                ),
                                "EUR"
                            )
                        }
                    ]
                },
                {
                    title: this.$t("portfolio.summary.cumulated-tri"),
                    items: [
                        {
                            section: this.$t("portfolio.summary.cumulated-tri-section"),
                            percentage: this.getTRI()
                        }
                    ]
                },
                {
                    title: this.$t("portfolio.summary.market-value-title"),
                    items: [
                        {
                            section: this.$t("portfolio.summary.valuation-section"),
                            type: "Total",
                            money: this.portfolioTotalValue(),
                            money_eur: this.getTotalAllCompanyByCurrency("EUR"),
                            money_usd: this.getTotalAllCompanyByCurrency("USD"),
                            total: this.getTotalAllCompany(this.valuation)
                        }
                    ]
                },
                {
                    title: this.$t("portfolio.summary.yield"),
                    items: [
                        {
                            section: this.$t("portfolio.summary.dividents-received"),
                            type: "Total",
                            money_eur: this.getYieldAllCompanyByCurrency("EUR"),
                            money_usd: this.getYieldAllCompanyByCurrency("USD"),
                            total: this.$currency.displayWithCurrency(
                                this.companies.reduce((acc, cur) => acc + cur.rendement, 0),
                                "EUR"
                            )
                        }
                    ]
                },
                {
                    title: this.$t("portfolio.summary.industry-diversification"),
                    chart: {
                        config: this.getIndustryChartConfig()
                    }
                },
                {
                    title: this.$t("portfolio.summary.geographic-diversification"),

                    chart: {
                        config: this.getCountryChartConfig()
                    }
                }
            ];
        },
        getRawTotal() {
            let total = 0;
            this.companies.forEach(company => {
                if (company.country.alphacode == "US") total += company.value * this.rateObj.EUR;
                else total += company.value;
            });
            return total;
        },
        // TODO: API call to get conversion for amount
        async getTotal(valuation, type) {
            const currencyTo = this.$entity.represented().currency_preferences;
            let key = "aggregate_" + type + "_" + currencyTo.toLowerCase();
            if ("exchange_rate" in valuation) this.rate = valuation.exchange_rate[currencyTo];
            else return {};
            return this.$currency.displayWithCurrency(valuation.aggregate[key], currencyTo);
        },
        getTotalAllCompany() {
            return this.$currency.displayWithCurrency(this.getRawTotal(), "EUR");
        },
        getTotalAllInvestment(type) {
            let total = 0;
            this.companies.forEach(company => {
                if (type == "USD" && company.country.alphacode == "US") {
                    total += Math.abs(company.investment_sum_eur);
                } else if (type == "EUR" && company.country.alphacode != "US") {
                    total += Math.abs(company.investment_sum_eur);
                }
            });
            return total;
        },
        getTotalAllCompanyByCurrency(type) {
            let total = 0;
            this.companies.forEach(company => {
                if (type == "USD" && company.country.alphacode == "US") total += company.value;
                else if (type == "EUR" && company.country.alphacode != "US") total += company.value;
            });
            return total;
        },
        getYieldAllCompanyByCurrency(type) {
            let total = 0;
            this.companies.forEach(company => {
                if (type == "USD" && company.country.alphacode == "US") total += company.rendement;
                else if (type == "EUR" && company.country.alphacode != "US")
                    total += company.rendement;
            });
            return total;
        },
        getTRI() {
            // Formule: Σ(TRI*invest)/Σ(invest)

            let dividend = 0;
            let divisor = 0;
            this.companies.forEach(company => {
                dividend += company.tri * Math.abs(company.investment_sum_eur);
                dividend += company.tri * Math.abs(company.investment_sum_usd);
                divisor += Math.abs(company.investment_sum_eur);
                divisor += Math.abs(company.investment_sum_usd);
            });

            return this.$currency.getPercent(divisor > 0 ? dividend / divisor : 0, 1);
        }
    }
};
</script>

<style lang="scss" scoped>
.summary-card {
    height: 100%;

    .section {
        font: Bold 21px/25px Proxima Nova;
        font-size: calc(100% + 0.3vw);
        letter-spacing: 0;
        color: #5a5a5a;
        max-height: 150px;
    }
    .subsection {
        font: Bold 17px/21px Proxima Nova;
        font-size: calc(100% + 0.15vw);
        letter-spacing: 0;
        color: #5a5a5a;
    }
    .money {
        font: Bold 35px/42px Proxima Nova;
        font-size: calc(100% + 0.7vw);
        letter-spacing: 0;
        color: #153055;
        margin-top: 0.3vw;
        margin-bottom: 0.3vw;
        text-align: center;
    }
    .percent {
        font: Regular 26px/32px Proxima Nova;
        font-size: calc(100% + 0.4vw);
        letter-spacing: 0;
        color: #28a328;
    }
}
</style>
