<template>
    <EqsDialog
        :value.sync="dialogProxy"
        v-bind="{
            title: currentInfoEntity.name
        }"
        closable
        @click:outside="closeDialog('close')"
    >
        <v-container v-if="investorData" class="container-info-modal">
            <h2 class="title-section">{{ $t("universe.kyoseil.info-modal.info") }}</h2>
            <v-row v-if="!investorData.is_company" justify="space-between">
                <v-col>{{ $t("registration.gender") }}</v-col>
                <v-col cols="7">{{ gender }}</v-col>
            </v-row>
            <v-row justify="space-between">
                <v-col>{{
                    investorData.is_company
                        ? $t("universe.kyoseil.tab.socialReason")
                        : $t("universe.kyoseil.info-modal.name")
                }}</v-col>
                <v-col cols="7">{{ name }}</v-col>
            </v-row>
            <v-row justify="space-between">
                <v-col>{{ $t("universe.kyoseil.info-modal.email") }}</v-col>
                <v-col cols="7">
                    <ContactLink
                        type="email"
                        :data="
                            investorData.is_company
                                ? orNull(investorData.contact_email)
                                : orNull(investorData.email)
                        "
                    />
                </v-col>
            </v-row>
            <v-row justify="space-between">
                <v-col>{{ $t("universe.kyoseil.info-modal.phone") }}</v-col>
                <v-col cols="7">
                    <ContactLink v-if="investorPhone" type="phone" :data="investorPhone" />
                    <span v-else>-</span>
                </v-col>
            </v-row>
            <v-row justify="space-between">
                <v-col>{{ $t("universe.kyoseil.info-modal.address") }}</v-col>
                <v-col cols="7">
                    <ContactLink type="address" :data="address" />
                </v-col>
            </v-row>

            <h2 class="title-section">{{ $t("universe.kyoseil.info-modal.state") }}</h2>
            <v-row justify="space-between">
                <v-col>{{ $t("universe.kyoseil.info-modal.kyc") }}</v-col>
                <v-col cols="7">
                    <KycStatus :loading="false" :entity="investorData" />
                </v-col>
            </v-row>
            <div v-if="investorData.is_company">
                <h2 class="title-section">
                    {{ $t("universe.kyoseil.kyc-investor.info-company") }}
                </h2>
                <v-row justify="space-between">
                    <v-col>CEO</v-col>
                    <v-col cols="7">
                        {{ orNull(investorData.ceo.name) }}
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col>{{ $t("registration.company.form-legal") }}</v-col>
                    <v-col cols="7">
                        {{ orNull(investorData.legal_type) }}
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col>{{ $t("registration.compnay-search-siren.siren-label") }}</v-col>
                    <v-col cols="7">
                        {{ orNull(investorData.company_idcode) }}
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col>{{ $t("registration.city") }}</v-col>
                    <v-col cols="7">
                        {{ orNull(investorData.incorporation_city) }}
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col>Code NAF</v-col>
                    <v-col cols="7">
                        {{ orNull(investorData.naf_code) }}
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col>{{ $t("registration.company.country-of-incorporation") }}</v-col>
                    <v-col cols="7">
                        {{ nationality }}
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col>{{ $t("registration.company.date-of-incorporation") }}</v-col>
                    <v-col cols="7">
                        {{ incorporation_date }}
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col>Capital</v-col>
                    <v-col cols="7">
                        {{ investorData.company_capital ? investorData.company_capital : "-" }}
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col>{{ $t("user.company.label-asset") }}</v-col>
                    <v-col cols="7">
                        {{ orNull(investorData.industry_type) }}
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col>{{ $t("registration.company.website") }}</v-col>
                    <v-col cols="7">
                        <ContactLink
                            v-if="strExists(investorData.company_website)"
                            type="link"
                            :data="investorData.company_website"
                        />
                        <span v-else>{{ orNull(investorData.company_website) }}</span>
                    </v-col>
                </v-row>
            </div>

            <div v-else>
                <h2 class="title-section">{{ $t("registration.general-info") }}</h2>
                <v-row justify="space-between">
                    <v-col>{{ $t("registration.nationality") }}</v-col>
                    <v-col cols="7">
                        {{ nationality }}
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col>{{ $t("registration.birth-date") }}</v-col>
                    <v-col cols="7">
                        {{ birth_date }}
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col>{{ $t("registration.birthplace") }}</v-col>
                    <v-col cols="7">
                        {{ `${orNull(investorData.birthplace)}, ${birthCountry}` }}
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col>{{ $t("registration.marital-situation") }}</v-col>
                    <v-col cols="7">
                        {{ orNull(investorData.marital_situation) }}
                    </v-col>
                </v-row>
            </div>

            <div v-if="strExists(investorData.biography)">
                <h2 class="title-section">{{ $t("universe.kyoseil.kyc-investor.bio") }}</h2>
                <v-row justify="space-between">
                    <v-col>
                        {{ orNull(investorData.biography) }}
                    </v-col>
                </v-row>
            </div>

            <h2 class="title-section">{{ $t("user.profile.company-docs.subtitle") }}</h2>
            <v-row justify="space-between">
                <v-col>
                    <EqsFileInput
                        v-for="(doc, index) in kycDocs"
                        :key="index"
                        v-bind="{
                            loading: false,
                            editable: false,
                            ...doc
                        }"
                    />
                </v-col>
            </v-row>

            <div v-if="investorData.is_company">
                <h2 class="title-section">
                    {{ $t("universe.kyoseil.kyc-investor.company-docs") }}
                </h2>
                <v-row justify="space-between">
                    <v-col>
                        <EqsFileInput
                            v-for="(doc, index) in companyDocs"
                            :key="index"
                            v-bind="{
                                loading: false,
                                editable: false,
                                ...doc
                            }"
                        />
                    </v-col>
                </v-row>
            </div>
            <v-row v-if="$entity.isAffiliationCgp()">
                <v-col class="text-center">
                    <v-btn
                        outlined
                        class="custom-btn"
                        :disabled="localLoading"
                        @click.stop="openDeleteModal = true"
                    >
                        <span class="mr-2">{{
                            $t("universe.kyoseil.info-modal.delete-investor")
                        }}</span>
                        <template v-if="!localLoading">
                            <v-icon color="red">mdi-delete-circle</v-icon>
                        </template>
                        <template v-else>
                            <v-progress-circular indeterminate color="primary" />
                        </template>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-if="error">
            <p>
                {{ $t("universe.kyoseil.kyc-investor.error-fetch") }}
            </p>
        </v-container>
        <DeleteInvestorModal
            v-if="investorData"
            :open.sync="openDeleteModal"
            :current-info-entity="currentInfoEntity"
            :id-entity="idEntity"
            @delete="deleteInvestor()"
            @closeDialog="
                () => {
                    openDeleteModal = false;
                }
            "
        />
    </EqsDialog>
</template>

<script>
import KycStatus from "../components/KycStatus";
import ContactLink from "../components/ContactLink";
import { getLabelFromCategory } from "@/util/documents";
import DeleteInvestorModal from "../modals/DeleteInvestorModal.vue";

export default {
    name: "InfoInvestorModal",
    components: { KycStatus, ContactLink, DeleteInvestorModal },
    inject: ["_entity"],
    props: {
        open: {
            type: Boolean,
            required: true
        },
        idEntity: {
            type: String,
            required: true
        },
        currentInfoEntity: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            investorData: null,
            error: false,
            docs: [],
            docsCompany: [],
            localLoading: false,
            openDeleteModal: false
        };
    },
    computed: {
        investorPhone() {
            if (this.currentInfoEntity.is_company) {
                return this.currentInfoEntity.ceo_phone;
            } else {
                return this.currentInfoEntity.phone;
            }
        },
        gender() {
            const gender = this.investorData.gender;
            if (!gender || !this.strExists(gender)) {
                return "-";
            }
            switch (gender) {
                case "male":
                    return this.$t("universe.kyoseil.kyc-investor.gender-male");
                case "female":
                    return this.$t("universe.kyoseil.kyc-investor.gender-female");
                default:
                    return "-";
            }
        },
        incorporation_date() {
            const date = this.investorData.incorporation_date;
            if (date && this.strExists(date)) {
                return new Date(date).toLocaleDateString();
            }
            return "-";
        },
        birth_date() {
            const date = this.investorData.birth_date;
            if (date && this.strExists(date)) {
                return new Date(date).toLocaleDateString();
            }
            return "-";
        },
        birthCountry() {
            return this.investorData?.birth_country?.name || "-";
        },
        nationality() {
            return this.investorData?.nationality?.name || "-";
        },
        address() {
            let country = "";
            if (this.investorData?.country?.name) {
                country = this.investorData.country.name;
            }
            return `${this.orNull(this.investorData.street)}, ${this.orNull(
                this.investorData.zipcode
            )} ${this.orNull(this.investorData.city)}, ${country}`;
        },
        name() {
            if (this.investorData.is_company) {
                return this.orNull(this.investorData.company_name);
            } else {
                return this.orNull(this.investorData.name);
            }
        },
        kycDocs() {
            return this.docs
                .filter(doc => doc)
                .map(doc => {
                    return { label: getLabelFromCategory(doc.category), value: doc };
                });
        },
        companyDocs() {
            return this.docsCompany
                .filter(doc => doc)
                .map(doc => {
                    return { label: getLabelFromCategory(doc.category), value: doc };
                });
        },
        dialogProxy: {
            get() {
                return this.open;
            },
            set(val) {
                if (!val) this.closeDialog();
            }
        }
    },
    async beforeMount() {
        this.fetchInvestorData(this.idEntity);
    },
    methods: {
        orNull(v) {
            if (!v) return "-";
            else return v;
        },
        strExists(v) {
            return v && typeof v === "string" && v.length > 0;
        },
        closeDialog() {
            this.loading = false;
            this.formData = {};
            this.$emit("closeDialog");
            this.$emit("update:open", false);
        },
        async fetchInvestorData(id) {
            this.investorData = null;
            try {
                const res = await this.$api.getEntityInfos(id, true);
                if (res.status === 200) {
                    this.investorData = res.data;
                } else {
                    this.error = true;
                }
            } catch (e) {
                this.error = true;
                console.info("ERROR: ", e);
                return;
            }
            this.listDocs();
        },
        listDocs() {
            const data = this.investorData;
            this.docs = [
                data.doc_idcard,
                data.doc_passport,
                data.doc_residence_permit,
                data.doc_license,
                data.doc_notice_of_assessment,
                data.proof_of_address_doc
            ];
            if (data.is_company) {
                this.docsCompany = [
                    data.doc_kbis,
                    data.doc_statutes,
                    data.doc_address,
                    data.doc_official_gazette_publication,
                    data.doc_notice_of_assessment
                ];
            }
        },
        async deleteInvestor() {
            this.localLoading = true;
            try {
                const res = await this.$api.deleteInvestorFromCgp(
                    this.$entity.represented().id,
                    this.idEntity
                );
            } catch (e) {
                console.log(e);
                this.$ui.error(`Impossible de supprimer : ${e.data}`);
                return;
            } finally {
                this.localLoading = false;
            }
            this.$ui.success(`Investisseur supprimé`);
            this.$store.commit("SET_AFFILIATION_RESET_CACHE", true);
            this.localLoading = false;
            this.$router.push({ name: "affiliation" });
        }
    }
};
</script>
<style scoped lang="scss">
.container-info-modal {
    text-align: start;
}

.label-text {
    color: #0d0d2b;
}

.title-section {
    font-weight: bolder;
    font-size: 1rem;
    color: #0d0d2b;
    margin-top: 1rem;
}

.custom-action-btn-client {
    text-transform: none !important;
    transition: 0.25s ease;

    &:hover {
        transform: scale(1.2);
    }
}
</style>
