<template>
    <div class="eqs-main-container margin-auto">
        <div class="d-flex flex-column fill-height">
            <v-row class="px-3">
                <v-col :cols="$reactive.isDesktop() ? 8 : 12">
                    <EqsTextDisplay
                        class="py-3"
                        eqs-type="title"
                        :value="$t('payments.stepper.add-payment-form.title')"
                    />
                    <EqsTextDisplay
                        class="py-3"
                        :value="$t('payments.stepper.add-payment-form.description')"
                    />
                    <EqsBtn
                        class="ma-3"
                        el-class="download-csv"
                        color="success"
                        :action="downloadCSV"
                    >
                        {{ $t("payments.stepper.add-payment-form.download-csv") }}
                    </EqsBtn>
                    <EqsTextDisplay
                        class="py-3"
                        eqs-type="subtitle"
                        :value="$t('payments.stepper.add-payment-form.subtitle')"
                    />
                    <EqsFileInput
                        :label="$t('payments.stepper.add-payment-form.upload')"
                        :value.sync="formData.doc"
                        :loading="loading || localLoading"
                        v-bind="{
                            accept: '.csv',
                            errorMessages: csvError,
                            ...schemaDouble.doc
                        }"
                        @update:value="parseCSV"
                    />
                    <div v-if="!!allData && allData.length > 0">
                        <EqsTextDisplay
                            class="py-3"
                            :value="$t('payments.stepper.add-payment-form.upload-success')"
                        />
                        <EqsTextDisplay
                            class="py-3"
                            eqs-type="subtitle"
                            :value="
                                $t('payments.stepper.add-payment-form.resume-information-subtitle')
                            "
                        />
                        <EqsTextDisplay
                            class="py-3"
                            :value="
                                $t('payments.stepper.add-payment-form.transaction-found-text', {
                                    '0': getQuantityOfTransaction(),
                                    '1': getTotalAmountOfTransaction(),
                                    '2': getQuantityOfBeneficiaries()
                                })
                            "
                        />
                        <v-input
                            v-bind="{
                                value: formData.validateData,
                                errorMessages: checkValideData()
                            }"
                            dense
                            hide-details="auto"
                        >
                            <EqsDataTable
                                v-bind="{
                                    headers,
                                    items: allData,
                                    loading: localLoading
                                }"
                                item-key="uikey"
                                show-expand
                                :selected.sync="formData.selectedData"
                                :expanded="getExpanded()"
                                show-select
                                :add="{
                                    txt: $t('payments.stepper.add-payment-form.select-all-btn'),
                                    fn: () => (formData.selectedData = allData)
                                }"
                                :footer-props="{
                                    itemsPerPageOptions: [30, 60, 100]
                                }"
                                :items-per-page="30"
                            >
                                <template #item.amount="{ value }"> {{ value }} € </template>
                                <template #expanded-item="{ headers: _headers, item }">
                                    <td :colspan="_headers.length">
                                        <v-row class="px-3">
                                            <v-col
                                                :cols="$reactive.getCols('half')"
                                                class="eqs-form-field"
                                            >
                                                <EqsDate
                                                    :value.sync="item.operation_date"
                                                    :label="$t('payments.label.date')"
                                                    :is-required="false"
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row class="px-3">
                                            <v-col
                                                :cols="$reactive.getCols('half')"
                                                class="eqs-form-field"
                                            >
                                                <EqsNumber
                                                    el-class="amount"
                                                    :label="$t('payments.label.amount')"
                                                    :value.sync="item.amount"
                                                    :is-currency="true"
                                                    :rules="[greaterThanZeroR]"
                                                    :is-required="false"
                                                    :loading="loading"
                                                />
                                            </v-col>
                                            <v-col
                                                :cols="$reactive.getCols('half')"
                                                class="eqs-form-field"
                                            >
                                                <EqsSelect
                                                    el-class="type"
                                                    :label="$t('payments.label.type')"
                                                    :placeholder="''"
                                                    :value.sync="item.operation_type"
                                                    :items="types"
                                                    item-text="txt"
                                                    item-value="id"
                                                    :is-required="false"
                                                    :loading="loading"
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row class="px-3">
                                            <v-col
                                                :cols="$reactive.getCols('half')"
                                                class="eqs-form-field"
                                            >
                                                <GlobalChooseInvestor
                                                    :label="
                                                        $t('payments.add-csv-file.label.credited')
                                                    "
                                                    :placeholder="''"
                                                    :value.sync="item.credited_entity"
                                                    :loading="loading"
                                                    :is-required="false"
                                                />
                                            </v-col>
                                            <v-col
                                                :cols="$reactive.getCols('half')"
                                                class="eqs-form-field"
                                            >
                                                <GlobalChooseInvestor
                                                    :label="
                                                        $t('payments.add-csv-file.label.debited')
                                                    "
                                                    :placeholder="''"
                                                    :value.sync="item.debited_entity"
                                                    :loading="loading"
                                                    :is-required="false"
                                                />
                                            </v-col>
                                        </v-row>
                                    </td>
                                </template>
                            </EqsDataTable>
                        </v-input>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import { greaterThanZeroR } from "@equisafe-ui-vue/src/rules";
import indexOf from "lodash/indexOf";
import { getRootURL } from "@/util";
import { types } from "@/util/payment.js";

export default {
    name: "AddPaymentCSVFile",
    mixins: [formMixin],
    props: {
        checkValideData: {
            type: Function,
            required: true
        },
        parseCSV: {
            type: Function,
            required: true
        },
        csvError: {
            type: Array,
            required: true
        },
        allData: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            greaterThanZeroR,
            doc: null,
            errorSelect: [],
            localLoading: false,
            types,
            headers: [
                {
                    text: this.$t("payments.stepper.add-payment-form.table.name"),
                    value: "credited_entity.name",
                    sortable: true
                },
                {
                    text: this.$t("payments.stepper.add-payment-form.table.price"),
                    value: "amount",
                    sortable: true
                }
            ]
        };
    },
    created() {
        this.localLoading = true;
        this.formData.selectedData = [];
        this.errorSelect = [];
        this.localLoading = false;
    },
    methods: {
        getExpanded() {
            let res = [];
            this.allData.forEach(elem => {
                if (elem.isValid == false) res.push(elem);
            });
            return res;
        },
        getQuantityOfTransaction() {
            return this.allData.length;
        },
        downloadCSV() {
            window.open(
                "https://public-documents-eqs.s3.eu-west-3.amazonaws.com/example_upload_payments.csv",
                "_blank"
            );
        },
        getTotalAmountOfTransaction() {
            let total = 0;
            this.allData.forEach(data => {
                total += parseFloat(data.amount);
            });
            return total + "€";
        },
        getQuantityOfBeneficiaries() {
            let beneficiaries = [];
            this.allData.forEach(data => {
                if (
                    !!data.credited_entity &&
                    indexOf(beneficiaries, data.credited_entity.name) == -1
                )
                    beneficiaries.push(data.credited_entity.name);
            });
            return beneficiaries.length;
        }
    }
};
</script>
