import { APIService } from "@/services/api.js";
import DEBUG from "@/store/debug.js";
import parser from "fast-xml-parser";
import isArray from "lodash/isArray";
import store from "@/store/store.js";

const getTranslations = async (api, promises) => {
    let translations = {};
    const results = await Promise.allSettled(
        promises.map(async item => {
            const res = await api.simpleRequest("GET", item.url);
            return {
                json: res.data,
                ...item
            };
        })
    );
    results.forEach(res => {
        if (res.status === "rejected") {
            console.error({ e: res.reason });
            throw res.reason.toString();
        } else {
            translations[res.value.name] = res.value.json;
        }
    });
    return { key: "translations", value: translations };
};

const getScripts = async (api, promises) => {
    let scripts = {};
    const results = await Promise.allSettled(
        promises.map(async item => {
            const res = await api.simpleRequest("GET", item.url);
            return {
                js: Function(res.data),
                ...item
            };
        })
    );
    results.forEach(res => {
        if (res.status === "rejected") {
            console.error({ e: res.reason });
            throw res.reason.toString();
        } else {
            scripts[res.value.name] = res.value.js;
        }
    });
    return { key: "scripts", value: scripts };
};

const getRestrictions = async (api, url) => {
    if (!url) return [];
    try {
        const res = await api.simpleRequest("GET", url);
        return { key: "routes_restrictions", value: res.data };
    } catch (e) {
        throw e;
    }
};

const getThemes = async (api, url) => {
    if (!url) return [];
    try {
        const res = await api.simpleRequest("GET", url);
        return { key: "themes", value: res.data };
    } catch (e) {
        throw e;
    }
};

const getUniverseContentFromS3 = async (api, s3_url, slug) => {
    try {
        // `https://universes-release.s3-eu-west-1.amazonaws.com?prefix=${universe_name}/`
        const res = await api.simpleRequest("GET", s3_url);
        const { ListBucketResult } = parser.parse(res.data);
        const { Contents } = ListBucketResult;
        const prependUrl = s3_url.replace(`?prefix=${slug}`, "").slice(0, -1);
        let s3Content = {
            images: {},
            translations: {},
            scripts: {},
            routes_restrictions: []
        };
        let translationPromises = [];
        let scriptsPromises = [];
        let restrictedRoutesUrl = "";
        let themesUrl = "";
        if (isArray(Contents))
            for (let { Key } of Contents) {
                if (process.env.NODE_ENV === "development") {
                    // Skip other universe in local, since index.html contains all
                    if (!Key.includes(`${slug}/`)) {
                        continue;
                    }
                }
                const url = `${prependUrl}/${Key}`;
                const name = Key.split("/").pop().split(".").shift();
                if (Key.includes("/images/") && !Key.endsWith("/images/")) {
                    const path = Key.split(".").shift().replace(`${slug}`, "");
                    s3Content.images[path] = url;
                } else if (Key.includes("/translation/") && !Key.endsWith("/translation/")) {
                    translationPromises.push({
                        url,
                        name
                    });
                } else if (Key.includes("/scripts/") && !Key.endsWith("/scripts/")) {
                    scriptsPromises.push({
                        url,
                        name
                    });
                } else if (Key.endsWith("/restricted_routes.json")) {
                    restrictedRoutesUrl = url;
                } else if (Key.endsWith("/themes.json")) {
                    themesUrl = url;
                }
            }
        const results = await Promise.allSettled([
            getThemes(api, themesUrl),
            getRestrictions(api, restrictedRoutesUrl),
            getTranslations(api, translationPromises),
            getScripts(api, scriptsPromises)
        ]);
        results.forEach(res => {
            if (res.status === "rejected") {
                console.error({ e: res.reason });
                throw res.reason.toString();
            } else {
                s3Content[res.value.key] = res.value.value;
            }
        });
        return s3Content;
    } catch (e) {
        throw e;
    }
};

export default async ({ commit, state }) => {
    DEBUG("GET_UNIVERSE_START");
    try {
        let res = null;
        const equisafe = {
            name: "Equisafe",
            slug: "equisafe"
        };
        if (store.getters.universe_id) {
            const api = new APIService();
            res = await api.getUniverse(store.getters.universe_id);
            const s3Content = await getUniverseContentFromS3(api, res.data.s3_url, res.data.slug);
            res.data = {
                ...res.data,
                ...s3Content
            };
        }

        DEBUG("GET_UNIVERSE_SUCCESS");
        commit("SET_UNIVERSE", !!res && !!res.data ? res.data : equisafe);
    } catch (e) {
        DEBUG("GET_UNIVERSE_ERROR");
        throw e;
    }
};
