<template>
    <EqsCard :title="$t('portfolio.label-asset')" v-bind="{ loading }">
        <div class="mt-3 ml-4 mb-3">
            <GlobalCompanyInviteByInvestorDialog
                :open.sync="openDialog"
                @closeDialog="
                    () => {
                        openDialog = false;
                    }
                "
            />
        </div>
        <EqsDataTable
            v-bind="{
                headers,
                searchable: {
                    placeholder: $t('label.search-companies')
                },
                selected: company ? [company] : [],
                loading: localLoading,
                items: companies,
                elClass: 'my-assets',
                sortBy: 'amount',
                sortDesc: [true]
            }"
            :add="{
                txt: $t('portfolio.label-invite-company'),
                fn: () => (openDialog = true)
            }"
        >
            <template #item.name="{ item }">
                {{ item.name }}
            </template>

            <template #item.tri="{ value }">
                {{ $currency.getPercent(value, 1) + " %" }}
            </template>
            <template #item.amount="{ item }">
                {{
                    $currency.displayWithCurrency(
                        Math.abs(item.investment_sum_eur),
                        item.country.alphacode == "US" ? "USD" : "EUR"
                    )
                }}
            </template>
            <template #item.actions="{ item }">
                <EqsBtn small text :el-class="`${item.name}-select`" @click="selectCompany(item)">
                    <v-icon>mdi-text-box-outline</v-icon>
                </EqsBtn>
            </template>
        </EqsDataTable>
    </EqsCard>
</template>

<script>
import GlobalCompanyInviteByInvestorDialog from "@/components/molecules/dialog/GlobalCompanyInviteByInvestorDialog";

export default {
    name: "MyAssets",
    components: {
        GlobalCompanyInviteByInvestorDialog
    },
    props: {
        companies: {
            type: Array,
            default: () => []
        },
        company: {
            default: null,
            type: Object
        },
        loading: {
            type: Boolean,
            required: true
        },
        localLoading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            openDialog: false,
            noDataMsg: this.$t("portfolio.no-assets-msg"),
            headers: [
                {
                    text: this.$t("portfolio.table.company-label"),
                    value: "name",
                    sortable: false,
                    filterable: true
                },
                {
                    text: this.$t("portfolio.table.amount-label"),
                    value: "amount",
                    sortable: true,
                    filterable: false
                },
                {
                    text: this.$t("portfolio.table.tri"),
                    value: "tri",
                    sortable: false,
                    filterable: false
                },
                {
                    text: this.$t("portfolio.table.details-label"),
                    value: "actions",
                    sortable: false,
                    filterable: false
                }
            ]
        };
    },
    methods: {
        selectCompany(company) {
            if (this.company && this.company.id === company.id) {
                this.$emit("update:company", null);
            } else {
                this.$emit("update:company", company);
            }
        }
    }
};
</script>
