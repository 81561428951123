<template>
    <v-container fluid>
        <v-row align="start" justify="space-between">
            <v-col md="7" cols="12">
                <h2 class="text-h4 mb-8">{{ $t("user.buy.steps.sub-form.title") }}</h2>
                <p>
                    {{ $t("user.buy.steps.sub-form.text-1") }}
                </p>
                <p style="text-align: center; font-size: 1.2rem; font-weight: bolder">
                    <EqsFileInput
                        v-bind="{
                            loading: false,
                            editable: false,
                            ...subDoc
                        }"
                    />
                </p>
                <p>{{ $t("user.buy.steps.sub-form.text-2") }}</p>
                <br />
                <p class="font-weight-black">{{ $t("user.buy.steps.sub-form.text-3") }}</p>
                <EqsFileInput
                    :value.sync="formData.custom_subscription_doc"
                    v-bind="{
                        elClass: 'custom_subscription_doc',
                        label: $t('documents.category.signed_capinc_document'),
                        deleteFile: true,
                        editable: true,
                        loading: false,
                        upload: upload,
                        dataType: 'file',
                        others: {
                            key: 'custom_subscription_doc'
                        },
                        isRequired: true
                    }"
                />
                <br />
                <p class="font-weight-black">
                    {{ $t("user.buy.steps.sub-form.text-proof-of-fund") }}
                </p>
                <EqsFileInput
                    :value.sync="formData.proof_of_fund"
                    v-bind="{
                        elClass: 'proof_of_fund',
                        label: $t('documents.category.proof_of_fund'),
                        deleteFile: true,
                        editable: true,
                        loading: false,
                        upload: upload,
                        dataType: 'file',
                        others: {
                            key: 'proof_of_fund'
                        },
                        isRequired: true
                    }"
                />
            </v-col>
            <v-col md="5" cols="12" class="order-first order-md-last">
                <SubscriptionCard :order="order" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { options as _options, fees as _fees } from "@/util/payment.js";
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import SubscriptionCard from "../components/SubscriptionCard.vue";

export default {
    name: "Bulletin",
    components: { SubscriptionCard },
    mixins: [formMixin],
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {};
    },
    computed: {
        subDoc() {
            return {
                label: `➔ ${this.$t("documents.category.signed_capinc_document")}`,
                value: this.order?.capinc.custom_bs?.custom_sub_doc
            };
        }
    },
    beforeMount() {
        if (this.order.custom_subscription_doc) {
            this.$set(this.formData, "custom_subscription_doc", this.order.custom_subscription_doc);
        }
    },
    methods: {
        async upload(docFormData) {
            const res = await this.$api.postDocument(docFormData);
            return res;
        }
    }
};
</script>

<style lang="scss" scoped></style>
