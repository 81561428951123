var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.showEntityInfos)?_c('v-btn',{staticClass:"custom-btn",attrs:{"outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDialogClick()}}},[_c('v-icon',[_vm._v("mdi-eye-outline")]),_vm._v(" "+_vm._s(_vm.$t("universe.kyoseil.drop-down-component.account-detail"))+" ")],1):_vm._e(),(_vm.showInvestorInfos)?_c('v-btn',{staticClass:"custom-btn",attrs:{"outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.modalInvestorInfosOpen()}}},[_c('v-icon',[_vm._v("mdi-eye-outline")]),_vm._v(" "+_vm._s(_vm.$t("universe.kyoseil.drop-down-component.account-detail"))+" ")],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.showAccountActivation)?_c('v-btn',{staticClass:"custom-btn custom-btn-blue",attrs:{"outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.accountActivationData.action.apply(null, arguments)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.accountActivationData.icon))]),_vm._v(" "+_vm._s(_vm.accountActivationData.title)+" ")],1):_vm._e(),(_vm.showImpersonate)?_c('v-btn',{staticClass:"custom-btn custom-btn-blue impersonate-btn",attrs:{"outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.impersonate()}}},[(_vm.impersonateLoading)?[_c('v-progress-circular',{attrs:{"indeterminate":""}})]:[_c('v-icon',[_vm._v("mdi-swap-horizontal")]),_vm._v(" "+_vm._s(_vm.$t("universe.kyoseil.client-deal.impersonate"))+" ")]],2):_vm._e()],1),(_vm.showInvestorInfos)?_c('InfoInvestorModal',_vm._b({attrs:{"open":_vm.isModalInvestorInfosOpen},on:{"update:open":function($event){_vm.isModalInvestorInfosOpen=$event},"closeDialog":() => {
                _vm.isModalInvestorInfosOpen = false;
            }}},'InfoInvestorModal',{
            idEntity: _vm.idEntity,
            currentInfoEntity: _vm.currentInfoEntity
        },false)):_vm._e(),(_vm.showEntityInfos)?_c('InfoEntityModal',_vm._b({attrs:{"open":_vm.selectClientDialogState},on:{"update:open":function($event){_vm.selectClientDialogState=$event},"closeDialog":() => {
                _vm.selectClientDialogState = false;
            }}},'InfoEntityModal',{
            idEntity: _vm.idEntity,
            currentInfoEntity: _vm.currentInfoEntity
        },false)):_vm._e(),(_vm.accountActivationData)?_c('AccountActivationModal',_vm._b({attrs:{"open":_vm.selectClientDialogDisableState},on:{"update:open":function($event){_vm.selectClientDialogDisableState=$event},"deactivate":() => {
                _vm.validateCGP('Rejected');
            },"closeDialog":() => {
                _vm.selectClientDialogDisableState = false;
            }}},'AccountActivationModal',{
            currentInfoEntity: _vm.currentInfoEntity
        },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }