<template>
    <div>
        <v-row no-gutters>
            <v-col>
                <EqsTextDisplay eqs-type="caption" :value="$t('portfolio.financial')" />
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col>
                <EqsTextDisplay eqs-type="subtitle" :value="$t('portfolio.totalinvestments')" />
            </v-col>
            <v-col>
                <EqsTextDisplay
                    :value="
                        $currency.displayWithCurrency(
                            Math.abs(data.investment_sum_eur),
                            getCurrency()
                        )
                    "
                    :is-required="false"
                    :editable="false"
                />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <EqsTextDisplay eqs-type="subtitle" :value="$t('portfolio.rendement')" />
            </v-col>
            <v-col>
                <EqsTextDisplay
                    :value="$currency.displayWithCurrency(Math.abs(data.rendement), getCurrency())"
                    :is-required="false"
                    :editable="false"
                />
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col>
                <EqsTextDisplay
                    eqs-type="subtitle"
                    :value="$t('portfolio.tri')"
                    :info-txt="$t('user.wallet.info-txt')"
                >
                    <v-icon class="mx-2" color="primary"> mdi-information </v-icon>
                </EqsTextDisplay>
            </v-col>
            <v-col>
                <EqsTextDisplay
                    :value="$currency.getPercent(data.tri, 1) + '%'"
                    :is-required="false"
                    :editable="false"
                />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <EqsTextDisplay eqs-type="subtitle" :value="$t('portfolio.totalvalue')" />
            </v-col>
            <v-col>
                <EqsTextDisplay
                    :value="$currency.displayWithCurrency(Math.abs(data.value), getCurrency())"
                    :is-required="false"
                    :editable="false"
                />
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col>
                <!-- :value="data.assets[0].shareledger.numberofshares.toString()" -->
                <EqsTextDisplay
                    class="mt-4"
                    eqs-type="caption"
                    :value="$t('portfolio.valorisation')"
                />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <EqsTextDisplay
                    eqs-type="subtitle"
                    :value="$t('portfolio.summary.market-value-title')"
                />
            </v-col>
            <v-col>
                <EqsTextDisplay
                    :value="$currency.displayWithCurrency(Math.abs(data.value), getCurrency())"
                    :is-required="false"
                    :editable="false"
                />
            </v-col>
        </v-row>
        <v-row v-if="data.venalValue" no-gutters>
            <v-col>
                <EqsTextDisplay eqs-type="subtitle" :value="$t('portfolio.summary.venal-value')" />
            </v-col>
            <v-col>
                <EqsTextDisplay
                    :value="
                        $currency.displayWithCurrency(
                            getQuotePart(data.venalValue, data.percent),
                            getCurrency()
                        )
                    "
                    :is-required="false"
                    :editable="false"
                />
            </v-col>
        </v-row>
        <v-row v-if="data.nav" no-gutters>
            <v-col>
                <EqsTextDisplay
                    eqs-type="subtitle"
                    :value="$t('shareledger.valuation.net-asset-value')"
                />
            </v-col>
            <v-col>
                <EqsTextDisplay
                    :value="
                        $currency.displayWithCurrency(
                            getQuotePart(data.nav, data.percent),
                            getCurrency()
                        )
                    "
                    :is-required="false"
                    :editable="false"
                />
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    name: "Analytics",
    props: {
        data: {
            type: Object,
            required: true
        },
        getCurrency: {
            type: Function,
            required: true
        }
    },
    methods: {
        getTotalPayments() {
            let total = 0;
            for (let dividend of this.data.dividends) {
                total += parseFloat(dividend.amount);
            }
            return total;
        },
        getQuotePart(value, percentage) {
            if (!value || !value.value_data || percentage <= 0) return 0;
            return Math.abs(value.value_data * percentage);
        }
    }
};
</script>
