<template>
    <div>
        <v-form ref="registerEmailForm" v-model="validForm">
            <EqsEmail
                id="emailRegister"
                el-class="email-register"
                :label="$t('register.label-email')"
                :value.sync="formData.email"
                class="mb-3"
                v-bind="{ loading }"
                :error-messages="errorMessages['email']"
            />
            <EqsPassword
                id="passwordRegister"
                el-class="password-register"
                :label="$t('register.label-password')"
                v-bind="{ loading }"
                class="mb-3"
                autocomplete="off"
                :value.sync="formData.password"
                :error-messages="errorMessages['password']"
            />
            <v-col
                :cols="
                    $reactive.getVal({
                        mobile: 10,
                        tablet: 8,
                        desktop: 8
                    })
                "
                class="ma-auto"
            >
                <EqsBtn
                    id="btnRegister"
                    color="primary"
                    block
                    v-bind="{ action: register, disabled: !validForm || loading }"
                    el-class="btn-register"
                    @update:loading="val => (loading = val)"
                >
                    {{ $t("auth.register.button") }}
                </EqsBtn>
            </v-col>
        </v-form>
        <div class="my-5">
            <EqsTextDisplay>
                <p v-html="$t('auth.register.legal-txt', { cgu: getCgu, privacy: getPrivacy })"
            /></EqsTextDisplay>
        </div>
        <div class="my-5">
            <a class="text-link" el-class="btn-login" @click="$router.push({ name: 'auth' })">{{
                $t("auth.register.sign-in")
            }}</a>
        </div>
    </div>
</template>

<script>
import { setCookie } from "@/util";

export default {
    name: "Register",
    data() {
        return {
            formData: {
                email: "",
                password: ""
            },
            validForm: false,
            loading: false,
            errorMessages: {
                email: [],
                password: []
            }
        };
    },

    computed: {
        email() {
            return this.formData.email.trim().toLowerCase();
        },
        password() {
            return this.formData.password;
        },
        getCgu() {
            if (!this.$universe.isEquisafe() && this.$store.getters.universe.cgu_url) {
                return this.$store.getters.universe.cgu_url;
            } else {
                return "https://www.equisafe.io/cgu-equisafe";
            }
        },
        getPrivacy() {
            if (!this.$universe.isEquisafe() && this.$store.getters.universe.privacy_policy_url) {
                return this.$store.getters.universe.privacy_policy_url;
            } else {
                return "https://www.equisafe.io/politique-de-confidentialite";
            }
        }
    },
    watch: {
        email: {
            handler: function (val, ...args) {
                this.errorMessages.email = [];
            }
        },
        password: {
            handler: function (val, ...args) {
                this.errorMessages.password = [];
            }
        }
    },
    methods: {
        registerGoodCredentials(res) {
            if (res && res.status == 201) {
                this.$ui.info(this.$t("rules.email-confirmation"));
                this.$router.push({
                    name: "register-email",
                    params: {
                        email: this.formData.email.trim().toLowerCase()
                    }
                });
            } else {
                this.$ui.error(this.$t("rules.correct-error"));
                if (res) {
                    this.errorMessages = res.data;
                    s;
                } else {
                    this.errorMessages = this.$t("rules.no-specific-errors");
                }
            }
        },
        registerBadCredentials(e) {
            if (
                e &&
                e.data.email &&
                (e.data.email[0] === "Un compte avec cet email existe déjà." ||
                    e.data.email[0] === "A record with this Email already exists.")
            )
                this.$ui.error(this.$t("rules.check-inbox"));
            else this.$ui.error(this.$t("rules.correct-error"));
            this.errorMessages = e.data;
        },
        async setCSRFToken() {
            try {
                const res = await this.$api.getCSRFToken();
                if (!res) return;
                const csrftoken = res.data;
                // setCookie("csrftoken2", csrftoken);
            } catch (e) {
                this.$ui.error(e, "login_get_token");
            }
        },
        async register() {
            if (this.$refs.registerEmailForm.validate()) {
                try {
                    await this.setCSRFToken();
                    const partnerCode = this.$route.query.partner_code;
                    const affiliateCode = this.$route.query.affiliate_code;
                    const data = { ...this.formData };
                    data.email = data.email.trim().toLowerCase();
                    const res = await this.$api.register({
                        ...data,
                        password_confirm: this.formData.password,
                        language: this.$language.current(),
                        partner_code: partnerCode,
                        affiliate_code: affiliateCode
                    });
                    this.registerGoodCredentials(res);
                } catch (e) {
                    this.registerBadCredentials(e);
                }
            }
        }
    }
};
</script>
