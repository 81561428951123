import { render, staticRenderFns } from "./InfoInvestorModal.vue?vue&type=template&id=87236e58&scoped=true"
import script from "./InfoInvestorModal.vue?vue&type=script&lang=js"
export * from "./InfoInvestorModal.vue?vue&type=script&lang=js"
import style0 from "./InfoInvestorModal.vue?vue&type=style&index=0&id=87236e58&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87236e58",
  null
  
)

export default component.exports