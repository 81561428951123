<template>
    <EqsDialog
        :value.sync="dialogProxy"
        v-bind="{
            title: currentInfoEntity ? currentInfoEntity.name : '-'
        }"
        closable
        @click:outside="closeDialog('close')"
    >
        <v-container v-if="currentInfoEntity" class="container-info-modal">
            <v-container
                v-if="accountValidation.status === 'waiting' && $entity.isAffiliationAdmin()"
                fluid
            >
                <h2 class="title-section">{{ $t("universe.kyoseil.info-modal.action") }}</h2>
                <p>{{ $t("universe.kyoseil.info-modal.intent") }}</p>
                <div class="d-flex justify-center">
                    <v-btn
                        class="mr-4 custom-action-btn-client"
                        color="#2e9e49"
                        dark
                        @click="validateCGP('Accepted')"
                    >
                        <v-icon> mdi-check </v-icon>
                        {{ $t("universe.kyoseil.info-modal.accept") }}
                    </v-btn>
                    <v-btn
                        class="ml-4 custom-action-btn-client"
                        color="rgb(220, 53, 69)"
                        dark
                        @click="validateCGP('Rejected')"
                    >
                        <v-icon> mdi-close </v-icon>
                        {{ $t("universe.kyoseil.info-modal.refuse") }}
                    </v-btn>
                </div>
            </v-container>
            <v-container v-if="!currentInfoEntity.is_verified" fluid>
                <v-row>
                    <v-col class="text-center">
                        <v-btn
                            outlined
                            class="custom-btn"
                            :disabled="localLoading || emailSent"
                            @click.stop="sendInvitationEmail()"
                        >
                            <span class="mr-2">{{
                                !emailSent
                                    ? $t("universe.kyoseil.info-modal.send-invit-email")
                                    : $t("universe.kyoseil.info-modal.sent-invit-email")
                            }}</span>
                            <template v-if="emailSent">
                                <v-icon color="success">mdi-check-circle-outline</v-icon>
                            </template>
                            <template v-else-if="!localLoading">
                                <v-icon>mdi-send</v-icon>
                            </template>
                            <template v-else>
                                <v-progress-circular indeterminate color="primary" />
                            </template>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <v-container fluid>
                <h2 class="title-section">{{ $t("universe.kyoseil.info-modal.info") }}</h2>
                <v-row justify="space-between">
                    <v-col>{{ $t("universe.kyoseil.info-modal.name") }}</v-col>
                    <v-col cols="7">{{ currentInfoEntity.name || "-" }}</v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col>{{ $t("universe.kyoseil.info-modal.email") }}</v-col>
                    <v-col cols="7">
                        <ContactLink type="email" :data="currentInfoEntity.email" />
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col>{{ $t("universe.kyoseil.info-modal.phone") }}</v-col>
                    <v-col cols="7">
                        <ContactLink type="phone" :data="currentInfoEntity.phone" />
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col>{{ $t("universe.kyoseil.info-modal.address") }}</v-col>
                    <v-col cols="7">
                        <ContactLink type="address" :data="currentInfoEntity.full_address" />
                    </v-col>
                </v-row>
                <h2 class="title-section">{{ $t("universe.kyoseil.info-modal.state") }}</h2>
                <v-row justify="space-between">
                    <v-col>{{ $t("universe.kyoseil.info-modal.kyc") }}</v-col>
                    <v-col cols="7">
                        <KycStatus :loading="false" :entity="currentInfoEntity" />
                    </v-col>
                </v-row>
                <v-row v-if="accountValidation.status !== 'waiting'" justify="space-between">
                    <v-col>{{ $t("universe.kyoseil.info-modal.active-acc") }}</v-col>
                    <v-col cols="7">
                        <StatusComponent
                            :is-admin="$entity.isAffiliationAdmin()"
                            :target="currentInfoEntity"
                            @updated="updateLocalStatus"
                        />
                    </v-col>
                </v-row>

                <div v-if="isEntityCgp(currentInfoEntity)" class="ma-0 pa-0">
                    <h2 class="title-section">{{ $t("universe.kyoseil.info-modal.docs") }}</h2>
                    <v-row justify="space-between">
                        <v-col>{{ $t("universe.kyoseil.info-modal.orias-number") }}</v-col>
                        <v-col cols="7">{{ currentInfoEntity.cgp_n_orias || "-" }}</v-col>
                    </v-row>

                    <v-row justify="space-between">
                        <v-col>{{ $t("documents.category.cgp_doc_orias") }}</v-col>
                        <v-col cols="7"
                            ><EqsFileInput
                                v-bind="{
                                    elClass: `cgp-doc-orias`,
                                    label: 'Document',
                                    deleteFile: false,
                                    editable: false,
                                    value: currentInfoEntity.cgp_doc_orias
                                }"
                        /></v-col>
                    </v-row>
                    <v-row justify="space-between">
                        <v-col>{{ $t("registration.cgp.rcpro") }}</v-col>
                        <v-col cols="7"
                            ><EqsFileInput
                                v-bind="{
                                    elClass: `cgp-doc-rcpro`,
                                    label: 'Document',
                                    deleteFile: false,
                                    editable: false,
                                    value: currentInfoEntity.cgp_doc_rcpro
                                }"
                        /></v-col>
                    </v-row>
                    <v-row v-if="$universe.isKyoseil()" justify="space-between">
                        <v-col>{{ $t("registration.cgp.convention") }}</v-col>
                        <v-col cols="7"
                            ><EqsFileInput
                                v-bind="{
                                    elClass: `cgp-doc-convention`,
                                    label: 'Document',
                                    deleteFile: false,
                                    editable: false,
                                    value: docConvention
                                }"
                        /></v-col>
                    </v-row>
                    <v-row justify="space-between">
                        <v-col>{{ $t("registration.cgp.association") }}</v-col>
                        <v-col cols="7">{{ currentInfoEntity.cgp_association || "-" }}</v-col>
                    </v-row>
                </div>
            </v-container>
        </v-container>
    </EqsDialog>
</template>

<script>
import { isEntityCgp, accountActivationStatus } from "@/components/views/user/clients/tools.js";
import KycStatus from "../components/KycStatus";
import StatusComponent from "../components/StatusComponent.vue";
import ContactLink from "../components/ContactLink";

export default {
    name: "InfoEntityModal",
    components: { KycStatus, StatusComponent, ContactLink },
    inject: ["_entity"],
    props: {
        open: {
            type: Boolean,
            required: true
        },
        idEntity: {
            type: String,
            required: true
        },
        currentInfoEntity: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            localLoading: false,
            accountValidation: null,
            emailSent: false
        };
    },
    computed: {
        dialogProxy: {
            get() {
                return this.open;
            },
            set(val) {
                if (!val) this.closeDialog();
            }
        },
        docConvention() {
            const document =
                this.currentInfoEntity?.cgp_convention_procedure?.proceduredocument_set;
            if (document && document.length) {
                return document[0]?.signed_document;
            } else {
                return null;
            }
        }
    },
    beforeMount() {
        this.accountValidation = accountActivationStatus(
            this.currentInfoEntity.attributes
                ? this.currentInfoEntity.attributes[0].validation_status
                : ""
        );
    },
    methods: {
        isEntityCgp,
        updateLocalStatus(newStatus) {
            this.accountValidation = newStatus;
            this.closeDialog();
            this.$store.commit("SET_AFFILIATION_RESET_CACHE", true);
        },
        async validateCGP(wantedStatus) {
            try {
                await window.app.$api.patchEntityAttributeStatus(
                    this.currentInfoEntity.attributes[0].id,
                    wantedStatus
                );
                wantedStatus === "Accepted"
                    ? this.$ui.success(
                          `${this.currentInfoEntity.name} ${this.$t(
                              "universe.kyoseil.status.success"
                          )}`
                      )
                    : this.$ui.error(
                          `${this.currentInfoEntity.name} ${this.$t(
                              "universe.kyoseil.status.rejected"
                          )}`
                      );
                this.closeDialog();
            } catch (error) {
                this.$ui.error(`${error.statusText}`);
                console.log("error Info Client");
            }
            this.$store.commit("SET_AFFILIATION_RESET_CACHE", true);
        },
        closeDialog() {
            this.loading = false;
            this.formData = {};
            this.$emit("closeDialog");
            this.$emit("update:open", false);
        },
        async sendInvitationEmail() {
            this.localLoading = true;
            try {
                const res = await this.$api.resendInvitationEmailKyoseil(this.idEntity);
                this.emailSent = true;
            } catch (error) {
                this.$ui.error("Erreur lors de l'envoi du mail, réessayez dans quelques instants");
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>
<style scoped lang="scss">
.container-info-modal {
    text-align: start;
}

.label-text {
    color: #0d0d2b;
}

.title-section {
    font-weight: bolder;
    font-size: 1rem;
    color: #0d0d2b;
    margin-top: 1rem;
}

.custom-action-btn-client {
    text-transform: none !important;
    transition: 0.25s ease;

    &:hover {
        transform: scale(1.2);
    }
}
</style>
