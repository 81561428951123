var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start","justify":"space-between"}},[_c('v-col',{attrs:{"md":"7","cols":"12"}},[_c('h2',{staticClass:"text-h4 mb-8"},[_vm._v(_vm._s(_vm.$t("user.buy.steps.sub-form.title")))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("user.buy.steps.sub-form.text-1"))+" ")]),_c('p',{staticStyle:{"text-align":"center","font-size":"1.2rem","font-weight":"bolder"}},[_c('EqsFileInput',_vm._b({},'EqsFileInput',{
                        loading: false,
                        editable: false,
                        ..._vm.subDoc
                    },false))],1),_c('p',[_vm._v(_vm._s(_vm.$t("user.buy.steps.sub-form.text-2")))]),_c('br'),_c('p',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.$t("user.buy.steps.sub-form.text-3")))]),_c('EqsFileInput',_vm._b({attrs:{"value":_vm.formData.custom_subscription_doc},on:{"update:value":function($event){return _vm.$set(_vm.formData, "custom_subscription_doc", $event)}}},'EqsFileInput',{
                    elClass: 'custom_subscription_doc',
                    label: _vm.$t('documents.category.signed_capinc_document'),
                    deleteFile: true,
                    editable: true,
                    loading: false,
                    upload: _vm.upload,
                    dataType: 'file',
                    others: {
                        key: 'custom_subscription_doc'
                    },
                    isRequired: true
                },false)),_c('br'),_c('p',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.$t("user.buy.steps.sub-form.text-proof-of-fund"))+" ")]),_c('EqsFileInput',_vm._b({attrs:{"value":_vm.formData.proof_of_fund},on:{"update:value":function($event){return _vm.$set(_vm.formData, "proof_of_fund", $event)}}},'EqsFileInput',{
                    elClass: 'proof_of_fund',
                    label: _vm.$t('documents.category.proof_of_fund'),
                    deleteFile: true,
                    editable: true,
                    loading: false,
                    upload: _vm.upload,
                    dataType: 'file',
                    others: {
                        key: 'proof_of_fund'
                    },
                    isRequired: true
                },false))],1),_c('v-col',{staticClass:"order-first order-md-last",attrs:{"md":"5","cols":"12"}},[_c('SubscriptionCard',{attrs:{"order":_vm.order}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }