import { i18n } from "@/services/plugins/i18n.js";
import store from "@/store/store";

export function calculateIncreasePercentage(lastWeek, currentWeek) {
    let change = currentWeek - lastWeek;
    let percentageChange = (change * 100.0) / lastWeek;
    return Number(percentageChange.toFixed(1));
}

export function validationStatusKyc(status) {
    switch (status) {
        case "success":
        case "Accepted":
            return {
                text: i18n.t("universe.kyoseil.status.success"),
                color: "success",
                icon: "mdi-check-circle",
                status: "success"
            };
        case "failure":
        case "upload-failure":
        case "Rejected":
            return {
                text: i18n.t("universe.kyoseil.status.rejected"),
                color: "error",
                icon: "mdi-close-circle",
                status: "fail"
            };
        case "upload":
        case "pending":
        case "TBD":
        case "waiting":
        default:
            return {
                text: i18n.t("universe.kyoseil.status.waiting"),
                color: "warning",
                icon: "mdi-clock-time-four",
                status: "waiting"
            };
    }
}

export function accountActivationStatus(status) {
    switch (status) {
        case "Accepted":
            return {
                text: i18n.t("universe.kyoseil.status.activated"),
                color: "success",
                icon: "mdi-check-circle",
                status: "success"
            };
        case "Rejected":
            return {
                text: i18n.t("universe.kyoseil.status.deactivated"),
                color: "error",
                icon: "mdi-close-circle",
                status: "fail"
            };
        default:
            return {
                text: i18n.t("universe.kyoseil.status.waiting"),
                color: "warning",
                icon: "mdi-clock-time-four",
                status: "waiting"
            };
    }
}

export function getViewFromRoute(route) {
    switch (route) {
        case "cgp_investor":
        case "bc_investor_of_cgp":
        case "admin_investor_of_cgp_of_bc":
            return "view_investor";
        case "bc_cgp":
        case "admin_cgp_of_bc":
            return "view_cgp";
        case "admin_bc":
            return "view_bc";
        // Should never happens
        default:
            return null;
    }
}

export function isRootPage(route) {
    return route.name === "affiliation";
}

export function getNextRoute(currentRoute, nextView) {
    if (isRootPage(currentRoute)) {
        // CGP
        if (nextView === "view_investor") {
            return "cgp_investor";
        }
        // BC AND ADMIN
        else if (nextView === "view_cgp") {
            return "bc_cgp";
        }
        // Admin
        else if (nextView === "view_bc") {
            return "admin_bc";
        }
    }
    // Admin
    else if (currentRoute.name === "bc_cgp") {
        if (nextView === "view_investor") {
            return "bc_investor_of_cgp";
        }
    }
    // Admin
    else if (currentRoute.name === "admin_bc") {
        if (nextView === "view_cgp") {
            return "admin_cgp_of_bc";
        }
    }
    // Admin
    else if (currentRoute.name === "admin_cgp_of_bc") {
        if (nextView === "view_investor") {
            return "admin_investor_of_cgp_of_bc";
        }
    }
    // Should never happens
    else {
        return null;
    }
}

function isEntityAdmin(entity) {
    return entity.is_affiliation_admin;
}

export function isEntityBc(entity) {
    if (entity.attributes?.length > 0) {
        if (entity.attributes.find(attr => attr.entity_type === "business_contributor")) {
            return true;
        }
    }
    return false;
}

export function isEntityCgp(entity) {
    if (entity.attributes?.length > 0) {
        if (entity.attributes.find(attr => attr.entity_type === "cgp")) {
            return true;
        }
    }
    return false;
}

export function isEntityInvestor(entity) {
    if (isEntityAdmin(entity) || isEntityBc(entity) || isEntityCgp(entity)) {
        return false;
    }
    return store.getters.useThroughRoles?.useIt;
}
